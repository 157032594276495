<template>
  <v-main class="the-content">
    <v-progress-linear v-if="loading" indeterminate color="primary" />
    <v-container id="app-container" fluid>
      <keep-alive v-if="shouldKeepAlive" :include="keepAliveComponents">
        <router-view />
      </keep-alive>
      <router-view v-else />
    </v-container>
  </v-main>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'TheContent',
  data: () => ({
    keepAliveComponents: ['IssuesInbox', 'IssuesTodo', 'IssuesUpcoming', 'IssuesDone'],
  }),
  computed: {
    shouldKeepAlive() {
      return this.keepAliveComponents.includes(this.$route.name)
    },
    ...mapGetters('app', ['loading']),
    ...mapGetters('auth', ['isLoggedIn']),
  },
}
</script>

<style lang="sass" scoped>
.the-content
  margin-top: constant(safe-area-inset-top) !important
  margin-top: env(safe-area-inset-top) !important
  margin-bottom: constant(safe-area-inset-bottom) !important
  margin-bottom: env(safe-area-inset-bottom) !important
</style>
