import { Model } from '@vuex-orm/core'

export default class Issue extends Model {
  static entity = 'issues'

  static fields() {
    return {
      id: this.attr(null),
      project_id: this.number(-1),
      project_name: this.string(''),
      status_id: this.number(Issue.STATUS.OPEN),
      subject: this.string(''),
      description: this.string('').nullable(),
      start_date: this.string('').nullable(),
      due_date: this.string('').nullable(),
      closed_on: this.string('').nullable(),
    }
  }

  get actress() {
    return this.subject.split(' ')[0]
  }

  get project_name2() {
    const t = this.project_name.split('–')[1]
    return t ? t.trim() : this.project_name
  }

  get subject2() {
    return this.subject.split(' ').slice(1).join(' ')
  }

  static STATUS = Object.freeze({
    OPEN: 1,
    TODO: 2,
    IN_PROGRESS: 3,
    DONE: 4,
  })
}
