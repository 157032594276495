const defaultState = () => ({
  visible: false,
  title: '',
  message: '',
  confirmText: '',
  onConfirm: null,
  confirming: false,
  cancelText: '',
  onCancel: null,
  canceling: false,
  canCancel: true,
  type: 'primary',
})

const actions = {
  open({ commit }, options) {
    commit('set', options)
    commit('visible', true)
  },
  async confirm({ state, commit }) {
    commit('confirming', true)
    if (state.onConfirm) {
      await state.onConfirm()
    }
    commit('confirming', false)
    commit('visible', false)
    setTimeout(() => {
      requestAnimationFrame(() => {
        commit('reset')
      })
    }, 300)
  },
  async cancel({ state, commit }) {
    commit('canceling', true)
    if (state.onCancel) {
      await state.onCancel()
    }
    commit('canceling', false)
    commit('visible', false)
    setTimeout(() => {
      requestAnimationFrame(() => {
        commit('reset')
      })
    }, 300)
  },
}

const mutations = {
  visible(state, visible) {
    state.visible = visible
  },
  set(state, options) {
    Object.keys(state).forEach((key) => {
      if (options[key] !== undefined) {
        state[key] = options[key]
      }
    })
  },
  confirming(state, confirming) {
    state.confirming = confirming
  },
  canceling(state, canceling) {
    state.canceling = canceling
  },
  reset(state) {
    const d = defaultState()
    Object.keys(state).forEach((key) => {
      state[key] = d[key]
    })
  },
}

const state = defaultState()

export default {
  namespaced: true,
  state,
  actions,
  mutations,
}
