<template>
  <div v-if="!loading">
    <template v-if="issues.length">
      <issue-list :issues="issues" />
    </template>
    <template v-else>
      <issues-no-data what="UPCOMING TODOS" />
    </template>
  </div>
</template>

<script>
import { isAfter, parseISO, startOfToday } from 'date-fns'
import { mapGetters } from 'vuex'
import IssueService from '../services/IssueService'
import PullToRefreshService from '../services/PullToRefreshService'
import IssuesNoData from '../components/issues/IssuesNoData.vue'
import Issue from '../models/Issue'
import IssueList from '@/components/issues/IssueList'

export default {
  name: 'IssuesUpcoming',
  components: {
    IssueList,
    IssuesNoData,
  },
  data: () => ({
    failSafeTimer: null,
  }),
  computed: {
    issues() {
      const now = startOfToday()
      return Issue.query()
        .where((_record, query) => {
          query.where('status_id', Issue.STATUS.TODO).orWhere('status_id', Issue.STATUS.IN_PROGRESS)
        })
        .where('start_date', (value) => isAfter(parseISO(value), now))
        .get()
    },
    ...mapGetters('app', ['loading']),
  },
  created() {
    this.$store.commit('app/setLoading', true)
    this.loadData().then(() => {
      this.$store.commit('app/setLoading', false)
    })
  },
  beforeDestroy() {
    clearTimeout(this.failSafeTimer)
  },
  activated() {
    PullToRefreshService.registerHandler(this.loadData.bind(this))
    this.failSafeTimer = setTimeout(() => {
      if (this.loading) {
        this.loadData()
      }
    }, 10000)
  },
  deactivated() {
    PullToRefreshService.unregisterHandler()
  },
  methods: {
    async loadData() {
      const data = await Promise.all([
        IssueService.get(Issue.STATUS.TODO),
        IssueService.get(Issue.STATUS.IN_PROGRESS),
      ])
      await Issue.insertOrUpdate({ data: data.flat() })
    },
  },
}
</script>
