<template>
  <v-theme-provider>
    <v-app>
      <the-app-bar @press:settings="drawerOpen = true" />
      <the-navigation-drawer v-model="drawerOpen" />
      <the-content />
      <the-bottom-navigation v-if="$vuetify.breakpoint.smAndDown" />
      <app-modal />
      <app-snackbar />
    </v-app>
  </v-theme-provider>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'

import TheAppBar from './components/layout/TheAppBar.vue'
import TheContent from './components/layout/TheContent.vue'
import TheBottomNavigation from './components/layout/TheBottomNavigation.vue'
import TheNavigationDrawer from './components/layout/TheNavigationDrawer.vue'
import AppModal from './components/feedback/AppModal.vue'
import AppSnackbar from './components/feedback/AppSnackbar.vue'

export default {
  name: 'App',
  components: {
    TheAppBar,
    TheContent,
    TheBottomNavigation,
    TheNavigationDrawer,
    AppModal,
    AppSnackbar,
  },
  data: () => ({
    drawerOpen: false,
  }),
  computed: {
    ...mapGetters('auth', ['isLoggedIn', 'token']),
  },
  watch: {
    token: {
      immediate: true,
      handler(value) {
        if (value) {
          axios.defaults.headers.common.Authorization = `Bearer ${value}`
        } else {
          delete axios.defaults.headers.common.Authorization
        }
      },
    },
  },
  created() {
    axios.interceptors.response.use(
      undefined,
      (error) =>
        new Promise(() => {
          if (error.response.status === 401) {
            this.$store.dispatch('snackbar/open', {
              message: error.response.data.error,
              type: 'error darken-1',
            })
            this.$store.dispatch('auth/logout').then(() => {
              this.$router.push('login')
            })
          }
        }),
    )
    if (this.isLoggedIn) {
      this.$store.dispatch('user/fetch')
    }
    this.$vuetify.theme.dark = !!this.$store.getters['app/darkMode']
  },
}
</script>

<style lang="sass">
.ptr--icon, .ptr--text
  color: rgba(158, 158, 158, .87) !important
</style>
