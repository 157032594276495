<template>
  <v-container fluid>
    <v-row justify="center" align="center">
      <v-col cols="12">
        <v-card class="mt-2-safe py-4" flat color="transparent">
          <v-img class="mx-auto my-4" max-height="160" max-width="160" src="../assets/logo.png" />
          <div class="text-center ma-4">
            <div class="text-h3 font-weight-bold">REDMINE GATEWAY</div>
          </div>
          <v-card-text>
            <v-form>
              <v-text-field v-model="username" outlined label="User ID" />
              <v-text-field
                v-model="password"
                autocomplete
                :append-icon="show ? 'mdi-eye-off' : 'mdi-eye'"
                outlined
                hide-details=""
                label="Password"
                :type="show ? 'text' : 'password'"
                @click:append="show = !show"
              />
            </v-form>
          </v-card-text>
          <v-card-actions class="px-4">
            <v-btn
              block
              large
              depressed
              color="primary darken-1"
              :loading="authenticating"
              @click="login()"
            >
              SIGN IN
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <div class="text-center text-caption text--secondary mt-4">
      (C) {{ currentYear }} Sarakedase Inc.
    </div>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Login',
  props: {
    redirect: {
      type: String,
      default: undefined,
    },
  },
  data: () => ({
    username: '',
    password: '',
    show: false,
  }),
  computed: {
    ...mapGetters('auth', ['authenticating', 'isLoggedIn']),
    currentYear() {
      return new Date().getFullYear()
    },
  },
  created() {
    if (this.isLoggedIn) {
      this.$router.push('/')
    }
  },
  methods: {
    async login() {
      try {
        await this.$store.dispatch('auth/login', {
          username: this.username,
          password: this.password,
        })
        await this.$router.push(this.redirect || '/')
        this.username = ''
        this.password = ''
      } catch (e) {
        this.$store.dispatch('snackbar/open', {
          message: e.message,
          type: 'error darken-1',
        })
      }
    },
    forceUpdate() {
      this.updating = true
      this.$store.commit('app/clear')
      this.$nextTick(async () => {
        if ('serviceWorker' in navigator) {
          const serviceWorkerRegistrations = await navigator.serviceWorker.getRegistrations()
          serviceWorkerRegistrations.forEach((serviceWorkerRegistration) => {
            serviceWorkerRegistration.unregister()
          })
        }
        if (caches) {
          const cacheKeys = await caches.keys()
          cacheKeys.forEach((cacheKey) => {
            caches.delete(cacheKey)
          })
        }
        window.location.reload(true)
      })
    },
  },
}
</script>

<style lang="sass" scoped>
.mt-2-safe
  margin-top: calc(8px + constant(safe-area-inset-top)) !important
  margin-top: calc(8px + env(safe-area-inset-top)) !important
</style>
