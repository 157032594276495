<template>
  <v-card rounded flat>
    <v-card-title class="font-weight-bold">{{ issue.subject2 }}</v-card-title>
    <v-card-subtitle>{{ issue.project_name2 }}</v-card-subtitle>
    <v-card-actions>
      <v-chip color="transparent" :text-color="dueDateColor" label>
        <v-icon left>{{ isOverdue ? 'mdi-fire' : 'mdi-calendar' }}</v-icon>
        <span :class="isOverdue ? 'font-weight-bold' : ''"> {{ formattedDueDate }}</span>
      </v-chip>
      <v-spacer />
      <v-btn class="pr-4 pl-3" color="secondary" :loading="loading" @click="onClickDone">
        <v-icon class="primary--text text--accent-4" left>mdi-check</v-icon>
        <span class="font-weight-bold">COMPLETE</span>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {
  format,
  parseISO,
  isAfter,
  isToday,
  isTomorrow,
  differenceInCalendarDays,
  isYesterday,
  startOfToday,
} from 'date-fns'
import IssueService from '@/services/IssueService'

export default {
  name: 'IssueItem',
  props: {
    issue: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    dueDate() {
      return parseISO(this.issue.due_date)
    },
    formattedDueDate() {
      const today = startOfToday()
      if (this.isOverdue) {
        if (isYesterday(this.dueDate)) {
          return 'Yesterday'
        }
        return `${differenceInCalendarDays(today, this.dueDate)} days overdue`
      }
      if (isToday(this.dueDate)) {
        return 'Today'
      }
      if (isTomorrow(this.dueDate)) {
        return 'Tomorrow'
      }
      if (differenceInCalendarDays(this.dueDate, today) < 7) {
        return format(this.dueDate, 'EEEE')
      }
      return format(this.dueDate, 'MMM d, yyyy')
    },
    dueDateColor() {
      if (this.isOverdue) return 'error'
      if (isToday(this.dueDate) || isTomorrow(this.dueDate)) return 'warning'
      return undefined
    },
    isOverdue() {
      const today = startOfToday()
      return isAfter(today, this.dueDate)
    },
  },
  methods: {
    async onClickDone() {
      this.loading = true
      await IssueService.finish(this.issue.id)
      this.loading = false
    },
  },
}
</script>
