<template>
  <v-navigation-drawer
    v-model="drawer"
    class="app-navigation-drawer"
    app
    disable-resize-watcher
    temporary
    right
  >
    <template v-slot:prepend>
      <v-list-item two-line>
        <v-list-item-avatar>
          <img :src="`https://avatars.dicebear.com/v2/female/${username}.svg`" />
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title>{{ fullname }}</v-list-item-title>
          <v-list-item-subtitle>{{ username }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </template>

    <v-divider />

    <v-list>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="text--secondary">App Version: 2.0.0</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-icon class="mr-4">
          <v-icon>mdi-weather-night</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>DARK MODE</v-list-item-title>
        </v-list-item-content>
        <v-list-item-action>
          <v-switch v-model="darkMode" hide-details inset />
        </v-list-item-action>
      </v-list-item>
      <v-list-item :ripple="false" @click="forceUpdate">
        <v-list-item-icon class="mr-4">
          <v-icon>mdi-cellphone-arrow-down</v-icon>
        </v-list-item-icon>
        <v-list-item-title v-if="updating">RELOADING...</v-list-item-title>
        <v-list-item-title v-else>RELOAD</v-list-item-title>
      </v-list-item>
    </v-list>

    <div class="pa-2">
      <v-btn block @click="logout">SIGN OUT</v-btn>
    </div>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'TheNavigationDrawer',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    updating: false,
  }),
  computed: {
    drawer: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    darkMode: {
      get() {
        return this.$store.getters['app/darkMode']
      },
      set(value) {
        this.$store.commit('app/setDarkMode', value)
        this.$vuetify.theme.dark = value
      },
    },
    ...mapGetters('user', ['username', 'fullname']),
  },
  methods: {
    logout() {
      this.$store.dispatch('auth/logout').then(() => {
        this.$router.push('login')
      })
    },
    forceUpdate() {
      this.updating = true
      this.$store.commit('app/clear')
      this.$nextTick(async () => {
        if ('serviceWorker' in navigator) {
          const serviceWorkerRegistrations = await navigator.serviceWorker.getRegistrations()
          serviceWorkerRegistrations.forEach((serviceWorkerRegistration) => {
            serviceWorkerRegistration.unregister()
          })
        }
        if (caches) {
          const cacheKeys = await caches.keys()
          cacheKeys.forEach((cacheKey) => {
            caches.delete(cacheKey)
          })
        }
        window.location.reload(true)
      })
    },
  },
}
</script>

<style lang="sass" scoped>
.app-navigation-drawer
  padding-top: constant(safe-area-inset-top) !important
  padding-top: env(safe-area-inset-top) !important
  padding-bottom: constant(safe-area-inset-bottom) !important
  padding-bottom: env(safe-area-inset-bottom) !important
</style>
