<template>
  <div v-if="!loading">
    <template v-if="projects.length">
      <v-toolbar flat color="transparent">
        <v-spacer />
        <v-slide-x-reverse-transition group hide-on-leave>
          <div v-if="selected.length === 0" :key="1">
            <v-btn color="primary darken-1" :loading="accepting" @click="acceptAll">
              <v-icon left>mdi-calendar-import</v-icon>
              <span>ACCEPT ALL</span>
            </v-btn>
          </div>
          <div v-else :key="2" class="flex align-center">
            <v-btn
              color="primary darken-1"
              class="mr-2"
              :loading="accepting"
              @click="acceptSelected"
            >
              <v-icon left>mdi-calendar-import</v-icon>
              <span>ACCEPT</span>
            </v-btn>
            <v-btn @click="declineSelected">IGNORE</v-btn>
          </div>
        </v-slide-x-reverse-transition>
      </v-toolbar>
      <fragment v-for="projectName in projects" :key="projectName">
        <v-subheader class="text-overline">
          {{ projectName }}
        </v-subheader>
        <v-list class="mb-6" two-line color="transparent">
          <v-list-item-group v-model="selected" multiple>
            <v-list-item
              v-for="issue in issues[projectName]"
              :key="issue.id"
              :value="issue.id"
              :ripple="false"
            >
              <template v-slot:default="{ active }">
                <v-list-item-action>
                  <v-simple-checkbox :value="active" />
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title class="font-weight-bold">
                    {{ issue.subject2 }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    <v-chip small class="px-0 text--secondary" color="transparent" label>
                      <v-icon small class="mr-2">mdi-calendar</v-icon>{{ issue.due_date | format }}
                    </v-chip>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </fragment>
    </template>
    <template v-else>
      <issues-no-data what="NEW TODOS" />
    </template>
    <v-dialog v-model="dialog" persistent max-width="600" scrollable>
      <v-card>
        <v-card-title
          ><p class="font-weight-bold">
            Are you sure you want to ignore these issues?
          </p></v-card-title
        >
        <v-card-subtitle>The issues below will be marked as completed immediately.</v-card-subtitle>
        <v-divider />
        <v-card-text>
          <v-list dense flat>
            <template v-for="(issue, i) in issuesSelected">
              <v-list-item :key="issue.id">
                <v-list-item-content>
                  <v-list-item-title>{{ issue.subject2 }}</v-list-item-title>
                  <v-list-item-subtitle
                    ><span class="text-caption">{{
                      issue.project_name2
                    }}</span></v-list-item-subtitle
                  >
                </v-list-item-content>
              </v-list-item>
              <v-divider v-if="i < issuesSelected.length - 1" :key="i" />
            </template>
          </v-list>
        </v-card-text>
        <v-divider />

        <v-card-actions>
          <v-spacer />
          <v-btn :disabled="declining" text @click="dialog = false">Cancel</v-btn>
          <v-btn color="error darken-1" :loading="declining" @click="submitDecline()">
            Yes, ignore them
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { format, parseISO } from 'date-fns'
import IssueService from '../services/IssueService'
import PullToRefreshService from '../services/PullToRefreshService'
import Fragment from '../components/layout/Fragment.vue'
import IssuesNoData from '../components/issues/IssuesNoData.vue'
import Issue from '../models/Issue'

export default {
  name: 'IssuesInbox',
  components: {
    Fragment,
    IssuesNoData,
  },
  filters: {
    format(date) {
      return format(parseISO(date), 'MMM d, yyyy')
    },
  },
  data: () => ({
    accepting: false,
    declining: false,
    failSafeTimer: null,
    selected: [],
    dialog: false,
  }),
  computed: {
    issues() {
      const issues = Issue.query().where('status_id', Issue.STATUS.OPEN).get()
      return issues.reduce((p, c) => {
        if (p[c.project_name2]) {
          p[c.project_name2].push(c)
        } else {
          p[c.project_name2] = [c]
        }
        return p
      }, {})
    },
    projects() {
      return Object.keys(this.issues)
    },
    issuesSelected() {
      return Issue.query().whereIdIn(this.selected).get()
    },
    ...mapGetters('app', ['loading']),
  },
  created() {
    this.$store.commit('app/setLoading', true)
    this.loadData().then(() => {
      this.$store.commit('app/setLoading', false)
    })
  },
  beforeDestroy() {
    clearTimeout(this.failSafeTimer)
  },
  activated() {
    PullToRefreshService.registerHandler(this.loadData.bind(this))
    this.failSafeTimer = setTimeout(() => {
      if (this.loading) {
        this.loadData()
      }
    }, 10000)
  },
  deactivated() {
    PullToRefreshService.unregisterHandler()
  },
  methods: {
    async loadData() {
      const data = await IssueService.get(Issue.STATUS.OPEN)
      await Issue.insertOrUpdate({ data })
    },
    async acceptAll() {
      this.accepting = true
      const ids = Issue.query()
        .where('status_id', Issue.STATUS.OPEN)
        .get()
        .map((issue) => issue.id)
      await IssueService.accept(ids)
      this.$store.dispatch('snackbar/open', {
        message: `All issues accepted!`,
        type: 'success darken-1',
      })
      this.accepting = false
    },
    async acceptSelected() {
      this.accepting = true
      await IssueService.accept(this.selected)
      this.$store.dispatch('snackbar/open', {
        message: `${this.selected.length} issue${this.selected.length > 1 ? 's' : ''} accepted!`,
        type: 'success darken-1',
      })
      this.selected = []
      this.accepting = false
    },
    declineSelected() {
      this.dialog = true
    },
    async submitDecline() {
      this.declining = true
      await IssueService.decline(this.selected)
      this.$store.dispatch('snackbar/open', {
        message: `${this.selected.length} issue${this.selected.length > 1 ? 's' : ''} ignored!`,
        type: 'success darken-1',
      })
      this.selected = []
      this.dialog = false
      setTimeout(() => {
        this.declining = false
      }, 300)
    },
  },
}
</script>
