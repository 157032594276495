const defaultState = () => ({
  visible: false,
  message: '',
  type: 'primary',
})

const actions = {
  open({ commit }, options) {
    commit('set', options)
    commit('visible', true)
  },
  async reset({ commit }) {
    setTimeout(() => {
      requestAnimationFrame(() => {
        commit('reset')
      })
    }, 300)
  },
}

const mutations = {
  visible(state, visible) {
    state.visible = visible
  },
  set(state, options) {
    Object.keys(state).forEach((key) => {
      if (options[key] !== undefined) {
        state[key] = options[key]
      }
    })
  },
  reset(state) {
    const d = defaultState()
    Object.keys(state).forEach((key) => {
      state[key] = d[key]
    })
  },
}

const state = defaultState()

export default {
  namespaced: true,
  state,
  actions,
  mutations,
}
