<template>
  <div />
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Index',
  computed: {
    ...mapGetters({
      isLoggedIn: 'auth/isLoggedIn',
      lastNavigation: 'app/lastNavigation',
    }),
  },
  created() {
    if (this.isLoggedIn) {
      this.$router.push(this.lastNavigation)
    } else {
      this.$router.push('login')
    }
  },
}
</script>
