<template>
  <v-dialog v-model="visible" max-width="600" persistent>
    <v-card>
      <v-card-title>
        {{ title }}
      </v-card-title>
      <v-card-text>
        {{ message }}
      </v-card-text>

      <v-card-actions>
        <v-spacer />

        <v-btn v-if="canCancel" text :loading="canceling" @click="cancel()">
          {{ cancelText }}
        </v-btn>

        <v-btn :color="type" text :loading="confirming" @click="confirm()">
          {{ confirmText }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'AppDialog',
  computed: {
    ...mapState('modal', [
      'visible',
      'title',
      'message',
      'confirmText',
      'onConfirm',
      'confirming',
      'cancelText',
      'onCancel',
      'canceling',
      'canCancel',
      'type',
    ]),
  },
  methods: {
    ...mapActions('modal', ['confirm', 'cancel']),
  },
}
</script>
