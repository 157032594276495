<template>
  <v-app-bar v-if="isLoggedIn" app dark color="grey darken-4" class="the-app-bar">
    <v-btn v-if="$route.meta.canBack" icon @click="$router.back()">
      <v-icon>mdi-arrow-left</v-icon>
    </v-btn>
    <v-img
      v-else
      class="ml-1 mr-3"
      src="../../assets/logo.png"
      max-height="24"
      max-width="24"
      contain
    />
    <v-toolbar-title class="pl-1 text-h5 font-weight-bold">
      {{ $route.meta.title }}
    </v-toolbar-title>
    <v-spacer />
    <v-toolbar-items v-if="$vuetify.breakpoint.mdAndUp" class="mr-2">
      <v-btn v-for="item in topNavigation" :key="item.route" :to="item.route" :ripple="false" text>
        <v-badge
          v-model="item.badge.show"
          color="error"
          bordered
          :content="item.badge.content"
          :dot="!!item.badge.dot"
          overlap
        >
          <v-icon>{{ item.icon }}</v-icon>
        </v-badge>
        <span class="ml-2">{{ item.text }}</span>
      </v-btn>
    </v-toolbar-items>
    <v-btn icon @click="$emit('press:settings')">
      <v-icon>mdi-cog</v-icon>
    </v-btn>
  </v-app-bar>
</template>

<script>
import { mapGetters } from 'vuex'
import { isBefore, parseISO, startOfToday } from 'date-fns'
import Issue from '@/models/Issue'

export default {
  name: 'TheAppBar',
  computed: {
    ...mapGetters('auth', ['isLoggedIn']),
    topNavigation() {
      const now = startOfToday()
      const openQuery = Issue.query().where('status_id', Issue.STATUS.OPEN)
      const todoQuery = Issue.query()
        .where((_record, query) => {
          query.where('status_id', Issue.STATUS.TODO).orWhere('status_id', Issue.STATUS.IN_PROGRESS)
        })
        .where('due_date', (value) => isBefore(parseISO(value), now))
      return [
        {
          icon: 'mdi-inbox',
          text: 'Inbox',
          route: 'inbox',
          badge: {
            show: openQuery.exists(),
            content: openQuery.get().length,
          },
        },
        {
          icon: 'mdi-calendar',
          text: 'ToDo',
          route: 'todo',
          badge: {
            show: todoQuery.exists(),
            content: '!',
          },
        },
        {
          icon: 'mdi-calendar-clock',
          text: 'Upcoming',
          route: 'upcoming',
          badge: {
            show: false,
          },
        },
        {
          icon: 'mdi-check',
          text: 'Done',
          route: 'done',
          badge: {
            show: false,
          },
        },
      ]
    },
  },
}
</script>

<style lang="sass" scoped>
.the-app-bar
  height: calc(56px + constant(safe-area-inset-top)) !important
  height: calc(56px + env(safe-area-inset-top)) !important

  ::v-deep .v-toolbar__content
    height: calc(56px + constant(safe-area-inset-top)) !important
    height: calc(56px + env(safe-area-inset-top)) !important
    padding: calc(4px + constant(safe-area-inset-top)) 16px 4px 16px !important
    padding: calc(4px + env(safe-area-inset-top)) 16px 4px 16px !important
</style>
