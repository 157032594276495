const getters = {
  lastNavigation: (state) => state.lastNavigation,
  darkMode: (state) => state.darkMode,
  loading: (state) => state.loading,
}

const mutations = {
  setLastNavigation(state, value) {
    state.lastNavigation = value
  },
  setDarkMode(state, value) {
    state.darkMode = value
  },
  setLoading(state, value) {
    state.loading = value
  },
  clear(state) {
    state.lastNavigation = 'inbox'
    state.darkMode = false
    state.loading = false
  },
}

const state = {
  lastNavigation: 'inbox',
  darkMode: false,
  loading: false,
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
}
