<template>
  <div v-if="!loading">
    <template v-if="issues.length">
      <v-alert v-if="overdueCount > 0" class="font-weight-bold" text type="error">
        You have {{ overdueCount }} overdue issue{{ overdueCount > 1 ? 's' : '' }}.<br />
        Please complete {{ overdueCount > 1 ? 'them' : 'it' }} immediately.
      </v-alert>
      <issue-list :issues="issues" />
    </template>
    <template v-else>
      <issues-no-data what="TODOS" />
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { isSameDay, isBefore, parseISO, startOfToday } from 'date-fns'
import IssueService from '../services/IssueService'
import PullToRefreshService from '../services/PullToRefreshService'
import IssuesNoData from '../components/issues/IssuesNoData.vue'
import Issue from '../models/Issue'
import IssueList from '@/components/issues/IssueList'

export default {
  name: 'IssuesTodo',
  components: {
    IssueList,
    IssuesNoData,
  },
  data: () => ({
    failSafeTimer: null,
  }),
  computed: {
    overdueCount() {
      const now = startOfToday()
      return Issue.query()
        .where((_record, query) => {
          query.where('status_id', Issue.STATUS.TODO).orWhere('status_id', Issue.STATUS.IN_PROGRESS)
        })
        .where('due_date', (value) => isBefore(parseISO(value), now))
        .get().length
    },
    issues() {
      const now = startOfToday()
      return Issue.query()
        .where((_record, query) => {
          query.where('status_id', Issue.STATUS.TODO).orWhere('status_id', Issue.STATUS.IN_PROGRESS)
        })
        .where(
          'start_date',
          (value) => isBefore(parseISO(value), now) || isSameDay(parseISO(value), now),
        )
        .get()
    },
    ...mapGetters('app', ['loading']),
  },
  created() {
    this.$store.commit('app/setLoading', true)
    this.loadData().then(() => {
      this.$store.commit('app/setLoading', false)
    })
  },
  beforeDestroy() {
    clearTimeout(this.failSafeTimer)
  },
  activated() {
    PullToRefreshService.registerHandler(this.loadData.bind(this))
    this.failSafeTimer = setTimeout(() => {
      if (this.loading) {
        this.loadData()
      }
    }, 10000)
  },
  deactivated() {
    PullToRefreshService.unregisterHandler()
  },
  methods: {
    async loadData() {
      const data = await Promise.all([
        IssueService.get(Issue.STATUS.TODO),
        IssueService.get(Issue.STATUS.IN_PROGRESS),
      ])
      await Issue.insertOrUpdate({ data: data.flat() })
    },
  },
}
</script>
