import PullToRefresh from 'pulltorefreshjs'

export default {
  registerHandler(handler) {
    PullToRefresh.init({
      mainElement: '#app-container',
      instructionsPullToRefresh: 'Pull To Refresh',
      instructionsReleaseToRefresh: 'Release To Refresh',
      instructionsRefreshing: 'Refresh In Progress',
      onRefresh: () => handler(),
    })
  },
  unregisterHandler() {
    PullToRefresh.destroyAll()
  },
}
