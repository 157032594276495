<template>
  <div>
    <div v-for="(item, i) in issueSortedByDueDate" :key="i" class="mb-6">
      <v-subheader class="text-overline">{{ item.title }}</v-subheader>
      <v-container fluid class="pa-0">
        <v-row dense>
          <v-col v-for="issue in item.issues" :key="issue.id" xs="12" sm="12" md="6" lg="4" xl="3">
            <issue-item :issue="issue" />
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import {
  isBefore,
  isAfter,
  parseISO,
  isToday,
  isTomorrow,
  differenceInCalendarDays,
  startOfToday,
} from 'date-fns'
import IssueItem from '@/components/issues/IssueItem'
export default {
  name: 'IssueList',
  components: { IssueItem },
  props: {
    issues: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    issueSortedByDueDate() {
      const today = startOfToday()
      const issues = this.issues
        .slice()
        .sort((a, b) => {
          if (isBefore(parseISO(a.due_date), parseISO(b.due_date))) {
            return -1
          }
          if (isAfter(parseISO(a.due_date), parseISO(b.due_date))) {
            return 1
          }
          return 0
        })
        .reduce((p, c) => {
          const date = parseISO(c.due_date)
          if (isAfter(today, date)) {
            if (p.overdue) {
              p.overdue.push(c)
            } else {
              p.overdue = [c]
            }
          } else if (isToday(date)) {
            if (p.today) {
              p.today.push(c)
            } else {
              p.today = [c]
            }
          } else if (isTomorrow(date)) {
            if (p.tomorrow) {
              p.tomorrow.push(c)
            } else {
              p.tomorrow = [c]
            }
          } else if (differenceInCalendarDays(date, today) < 7) {
            if (p.thisWeek) {
              p.thisWeek.push(c)
            } else {
              p.thisWeek = [c]
            }
          } else {
            if (p.afterNextWeek) {
              p.afterNextWeek.push(c)
            } else {
              p.afterNextWeek = [c]
            }
          }
          return p
        }, {})

      return Object.entries(issues).map(([k, v]) => {
        if (k === 'overdue') return { title: 'OVERDUE', issues: v }
        if (k === 'today') return { title: 'TODAY', issues: v }
        if (k === 'tomorrow') return { title: 'TOMORROW', issues: v }
        if (k === 'thisWeek') return { title: 'THIS WEEK', issues: v }
        return { title: 'NEXT WEEK OR LATER', issues: v }
      })
    },
  },
}
</script>
