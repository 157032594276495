import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import Index from '../views/Index.vue'
import Login from '../views/Login.vue'
import IssuesInbox from '../views/IssuesInbox.vue'
import IssuesTodo from '../views/IssuesTodo.vue'
import IssuesUpcoming from '../views/IssuesUpcoming.vue'
import IssuesDone from '@/views/IssuesDone'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Index',
    component: Index,
    meta: {
      title: '',
      isPublic: true,
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    props: (route) => ({
      redirect: route.query.redirect,
    }),
    meta: {
      title: 'SIGN IN',
      isPublic: true,
    },
  },
  {
    path: '/inbox',
    name: 'IssuesInbox',
    component: IssuesInbox,
    meta: {
      title: 'INBOX',
      shouldSaveLastNavigation: true,
    },
  },
  {
    path: '/todo',
    name: 'IssuesTodo',
    component: IssuesTodo,
    meta: {
      title: 'TODO',
      shouldSaveLastNavigation: true,
    },
  },
  {
    path: '/upcoming',
    name: 'IssuesUpcoming',
    component: IssuesUpcoming,
    meta: {
      title: 'UPCOMING',
      shouldSaveLastNavigation: true,
    },
  },
  {
    path: '/done',
    name: 'IssuesDone',
    component: IssuesDone,
    meta: {
      title: 'DONE',
      shouldSaveLastNavigation: true,
    },
  },
]

const router = new VueRouter({
  routes,
})

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => !record.meta.isPublic) && !store.getters['auth/isLoggedIn']) {
    next({
      path: '/login',
      query: {
        redirect: to.path,
      },
    })
  } else {
    next()
  }
})

router.afterEach((to) => {
  const resolved = router.resolve(to.path)
  if (resolved.route.meta.shouldSaveLastNavigation) {
    store.commit('app/setLastNavigation', to.path)
  }
})

export default router
