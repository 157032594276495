import axios from 'axios'

const getters = {
  isLoggedIn: (state) => !!state.token,
  token: (state) => state.token,
  authenticating: (state) => state.status === 'loading',
}

const actions = {
  async login({ commit }, auth) {
    commit('loginRequest')
    const body = new URLSearchParams()
    Object.entries(auth).forEach(([key, value]) => {
      body.append(key, value)
    })
    const resp = await axios.post('/api/login.php', body)
    if (resp.data.error) {
      commit('loginFailure')
      throw new Error(resp.data.error)
    }
    commit('loginSuccess', resp.data.token)
    commit('user/set', resp.data.user, { root: true })
  },
  async logout({ commit }) {
    commit('clear')
    delete axios.defaults.headers.common.Authorization
  },
}

const mutations = {
  loginRequest(state) {
    state.status = 'loading'
  },
  loginSuccess(state, token) {
    state.status = 'succeeded'
    state.token = token
  },
  loginFailure(state) {
    state.status = 'failed'
  },
  clear(state) {
    state.token = ''
    state.status = ''
  },
}

const state = {
  token: '',
  status: '',
  errorMessage: '',
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}
