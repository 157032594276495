<template>
  <v-snackbar
    v-model="snackbar"
    class="app-snackbar"
    top
    :timeout="3000"
    :color="type"
    :elevation="0"
  >
    {{ message }}
  </v-snackbar>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'AppSnackbar',
  computed: {
    snackbar: {
      get() {
        return this.visible
      },
      set(value) {
        this.$store.commit('snackbar/visible', value)
        if (!value) {
          this.$store.dispatch('snackbar/reset')
        }
      },
    },
    ...mapState('snackbar', ['type', 'visible', 'message']),
  },
}
</script>

<style lang="sass" scoped>
.app-snackbar
  top: calc(8px + constant(safe-area-inset-top)) !important
  top: calc(8px + env(safe-area-inset-top)) !important
</style>
