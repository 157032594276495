import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import VuexORM from '@vuex-orm/core'

import app from './modules/app'
import auth from './modules/auth'
import modal from './modules/modal'
import snackbar from './modules/snackbar'
import user from './modules/user'

import Issue from '../models/Issue'

Vue.use(Vuex)

const database = new VuexORM.Database()
database.register(Issue)

export default new Vuex.Store({
  modules: {
    app,
    auth,
    modal,
    snackbar,
    user,
  },
  plugins: [
    createPersistedState({
      paths: ['app.lastNavigation', 'app.darkMode', 'auth.token'],
      fetchBeforeUse: true,
    }),
    VuexORM.install(database),
  ],
})
