<template>
  <div v-if="!loading">
    <div class="d-flex align-center justify-center">
      <v-btn-toggle v-model="target" mandatory dense color="primary" borderless>
        <v-btn value="lastMonth" :ripple="false" class="px-4">Last month</v-btn>
        <v-btn value="thisMonth" :ripple="false" class="px-4">This month</v-btn>
      </v-btn-toggle>
    </div>
    <template v-if="issues.length">
      <v-list class="mb-6" two-line color="transparent" dense>
        <v-list-item v-for="issue in issues" :key="issue.id">
          <v-list-item-content>
            <v-list-item-title class="font-weight-bold">
              {{ issue.subject }}
            </v-list-item-title>
            <v-list-item-subtitle class="font-weight-bold">
              Completed on: {{ issue.closed_on | format }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </template>
    <template v-else>
      <issues-no-data what="COMPLETED TODOS" />
    </template>
  </div>
</template>

<script>
import { format, isAfter, isBefore, parseISO } from 'date-fns'
import { mapGetters } from 'vuex'
import IssueService from '../services/IssueService'
import PullToRefreshService from '../services/PullToRefreshService'
import IssuesNoData from '../components/issues/IssuesNoData.vue'
import Issue from '../models/Issue'

export default {
  name: 'IssuesDone',
  components: {
    IssuesNoData,
  },
  filters: {
    format(date) {
      return format(parseISO(date), 'MMM d, yyyy')
    },
  },
  data: () => ({
    target: 'thisMonth',
    failSafeTimer: null,
  }),
  computed: {
    issues() {
      return Issue.query()
        .where('status_id', Issue.STATUS.DONE)
        .where('closed_on', (value) => {
          if (this.target === 'thisMonth') {
            return isAfter(parseISO(value), parseISO(`${this.thisMonth}-01`))
          } else if (this.target === 'lastMonth') {
            return isBefore(parseISO(value), parseISO(`${this.thisMonth}-01`))
          } else {
            return true
          }
        })
        .get()
    },
    thisMonth() {
      const d = new Date()
      const thisMonth = (d.getMonth() + 1).toString().padStart(2, '0')
      const thisMonthYear = d.getFullYear().toString()
      return `${thisMonthYear}-${thisMonth}`
    },
    lastMonth() {
      const d = new Date()
      d.setDate(0)
      const previousMonth = (d.getMonth() + 1).toString().padStart(2, '0')
      const previousMonthYear = d.getFullYear().toString()
      return `${previousMonthYear}-${previousMonth}`
    },
    ...mapGetters('app', ['loading']),
  },
  created() {
    this.$store.commit('app/setLoading', true)
    this.loadData().then(() => {
      this.$store.commit('app/setLoading', false)
    })
  },
  beforeDestroy() {
    clearTimeout(this.failSafeTimer)
  },
  activated() {
    PullToRefreshService.registerHandler(this.loadData.bind(this))
    this.failSafeTimer = setTimeout(() => {
      if (this.loading) {
        this.loadData()
      }
    }, 10000)
  },
  deactivated() {
    PullToRefreshService.unregisterHandler()
  },
  methods: {
    async loadData() {
      const data = await Promise.all([
        IssueService.get(Issue.STATUS.DONE, this.thisMonth),
        IssueService.get(Issue.STATUS.DONE, this.lastMonth),
      ])
      await Issue.insertOrUpdate({ data: data.flat() })
    },
  },
}
</script>
