<template>
  <v-bottom-navigation v-if="isLoggedIn" class="app-bottom-navigation" app grow>
    <v-btn v-for="item in bottomNavigation" :key="item.route" :to="item.route" :ripple="false">
      <span>{{ item.text }}</span>
      <v-badge
        v-model="item.badge.show"
        color="error"
        bordered
        :content="item.badge.content"
        :dot="!!item.badge.dot"
        overlap
      >
        <v-icon>{{ item.icon }}</v-icon>
      </v-badge>
    </v-btn>
  </v-bottom-navigation>
</template>

<script>
import { isBefore, parseISO, startOfToday } from 'date-fns'
import { mapGetters } from 'vuex'
import Issue from '../../models/Issue'

export default {
  name: 'TheBottomNavigation',
  computed: {
    ...mapGetters('auth', ['isLoggedIn']),
    bottomNavigation() {
      const now = startOfToday()
      const openQuery = Issue.query().where('status_id', Issue.STATUS.OPEN)
      const todoQuery = Issue.query()
        .where((_record, query) => {
          query.where('status_id', Issue.STATUS.TODO).orWhere('status_id', Issue.STATUS.IN_PROGRESS)
        })
        .where('due_date', (value) => isBefore(parseISO(value), now))
      return [
        {
          icon: 'mdi-inbox',
          text: 'Inbox',
          route: 'inbox',
          badge: {
            show: openQuery.exists(),
            content: openQuery.get().length,
          },
        },
        {
          icon: 'mdi-calendar',
          text: 'ToDo',
          route: 'todo',
          badge: {
            show: todoQuery.exists(),
            content: '!',
          },
        },
        {
          icon: 'mdi-calendar-clock',
          text: 'Upcoming',
          route: 'upcoming',
          badge: {
            show: false,
          },
        },
        {
          icon: 'mdi-check',
          text: 'Done',
          route: 'done',
          badge: {
            show: false,
          },
        },
      ]
    },
  },
}
</script>

<style lang="sass" scoped>
.app-bottom-navigation
  height: auto !important

  ::v-deep .v-btn__content
    padding-top: 9px
    padding-bottom: 9px
    margin-bottom: constant(safe-area-inset-bottom) !important
    margin-bottom: env(safe-area-inset-bottom) !important
</style>
