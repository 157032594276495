import axios from 'axios'

const getters = {
  username: (state) => state.id,
  fullname: (state) => {
    if (/[a-z]/i.test(state.lastname)) {
      return `${state.firstname} ${state.lastname}`
    }
    return `${state.lastname} ${state.firstname}`
  },
}

const actions = {
  async fetch({ commit }) {
    const resp = await axios.get('/api/user.php')
    commit('set', resp.data)
  },
}

const mutations = {
  set(state, user) {
    state.id = user.login
    state.lastname = user.lastname
    state.firstname = user.firstname
  },
}

const state = {
  id: '',
  lastname: '',
  firstname: '',
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
