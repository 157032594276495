import axios from 'axios'
import Issue from '../models/Issue'

export default {
  async get(statusId, closedOn) {
    const resp = await axios.get('/api/issues.php', {
      params: { status_id: statusId, closed_on: closedOn },
    })
    return resp.data
  },

  async accept(issueIds) {
    const body = new URLSearchParams()
    issueIds.forEach((issueId) => {
      body.append('issue_id[]', issueId)
    })
    await axios.post('/api/issues-accept.php', body)
    await Issue.update({
      where: (issue) => issueIds.includes(issue.id),
      data: {
        status_id: Issue.STATUS.TODO,
      },
    })
  },

  async decline(issueIds) {
    const body = new URLSearchParams()
    issueIds.forEach((issueId) => {
      body.append('issue_id[]', issueId)
    })
    await axios.post('/api/issues-decline.php', body)
    await Promise.all(issueIds.map((issueId) => Issue.delete(issueId)))
  },

  async finish(issueId) {
    const body = new URLSearchParams()
    body.append('issue_id', issueId)
    await axios.post('/api/issues-finish.php', body)
    await Issue.delete(issueId)
  },
}
